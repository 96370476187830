









































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import BezeButton from '@/components/common/BezeButton.vue'
import WidgetTitle from '@/components/common/WidgetTitle.vue'
import BezeInput from '@/components/common/BezeInput.vue'
import { mask } from 'vue-the-mask'
import Datepicker from 'vuejs-datepicker'
import { ru } from 'vuejs-datepicker/dist/locale'
import CartTotal from '@/components/cart/CartTotal.vue'
import { PREPAY_METHOD, PREPAY, DELIVERY_SLOTS, CARD_ID, LIMITED_COUPON_ID, SCHOOL_COUPON_ID, POLICY_LINK } from '@/shared/const'
import PrivacyText from '@/components/common/PrivacyText.vue'
import OrderAPI from '@/api/orders'
import API from '@/api/products'
import { DeliveryType, OrderInfo } from '@/shared/models/orders'
import { ProductListItem } from '@/shared/models/cakes'
import { IUserData } from '@/shared/models/auth'
import { debounce } from '@/shared/helpers'
import BDayCardIcon from '@/components/icons/BDayCardIcon.vue'

@Component({
  components: {
    BezeButton,
    BezeInput,
    WidgetTitle,
    Datepicker,
    CartTotal,
    PrivacyText,
    BDayCardIcon
  },
  directives: {
    mask
  }
})
export default class Checkout extends Vue {
  isPaymentActive = false
  isBtnLoading = false
  isPolicyAccepted = false
  isAgreementAccepted = false
  areControlsActive = true
  promoCode = ''
  promoCodeMessage = ''
  isPromoCodeFetching = false
  orderHasCard = false
  isCardLoading = true
  isSignInMsgHidden = !!window.localStorage.getItem('checkoutSignInMsgHidden')
  expensiveDates: number[] = []
  order: OrderInfo = {
    userId: this.userData?.id || null,
    firstName: this.userData?.first_name || '',
    lastName: this.userData?.last_name || '',
    phone: this.userData?.billing.phone || '',
    email: this.userData?.email || '',
    deliveryDate: null,
    deliveryAddress: this.userData?.shipping.address_1 || '',
    comment: '',
    cardText: '',
    deliverySlot: '',
    prepayMethod: PREPAY.FULL.method,
    deliveryTypeID: this.deliveryMethods[0]?.id,
    hasAllergy: false,
    forOther: false
  }

  cardData: ProductListItem | null = null

  otherCustomerInfo = {
    name: '',
    phone: ''
  }

  data(): Record<string, unknown> {
    return {
      ru,
      PREPAY,
      PREPAY_METHOD,
      DELIVERY_SLOTS,
      POLICY_LINK,
      searchDebounce: debounce(this.checkPromoCode, 500)
    }
  }

  get isUserLogged(): boolean {
    return this.$store.getters.userToken
  }

  get isOrderCorrect(): boolean {
    return this.order.lastName.length > 1 &&
      this.order.firstName.length > 1 &&
      this.order.phone?.length > 7 &&
      !!this.order.deliveryTypeID &&
      !!this.order.deliveryDate &&
      !!this.order.deliverySlot &&
      (this.isDeliveryActive ? (!!this.order.deliveryAddress) : true)
  }

  // очень грязный хак чтобы заранее показывать повышенную стоимость доставки в праздники
  get isPickedDeliveryDateExpensive(): boolean {
    return this.order.deliveryDate ? this.expensiveDates.includes(new Date(this.order.deliveryDate).getDate()) : false
  }

  get cartHasVariationProduct(): boolean {
    return this.$store.getters.hasVariableItem
  }

  get expensiveDeliveryCost(): DeliveryType {
    return this.$store.getters.deliveryMethods.find((method: DeliveryType) => !method.enabled)
  }

  get availableDates(): Record<string, Date> {
    if (this.cartHasVariationProduct || this.productsWith3daysDelivery.length) {
      return { to: new Date(Date.now() + (48 * 60 * 60 * 1000)) }
    } else if (this.productsWithLongDelivery.length) {
      return { to: new Date() }
    } else {
      return { to: new Date(Date.now() - (24 * 60 * 60 * 1000)) }
    }
  }

  get courierDeliveryMethod(): DeliveryType | null {
    return this.deliveryMethods.find(method => method.method_id === 'flat_rate') || null
  }

  get isDeliveryActive(): boolean {
    return this.order.deliveryTypeID === this.courierDeliveryMethod?.id
  }

  get currentCart(): Array<ProductListItem> {
    return this.$store.getters.cart || []
  }

  get userData(): IUserData {
    return this.$store.getters.userData
  }

  get deliveryMethods(): Array<DeliveryType> {
    return this.$store.getters.deliveryMethods.filter((method: DeliveryType) => method.enabled)
  }

  get isCakeHasPrepay(): boolean {
    return this.$store.getters.cart
      .filter((item: ProductListItem) => item.id !== CARD_ID)
      .some((cake: ProductListItem) => !['regular', 'desserts', 'gastroboksy'].includes(cake.categories[0].slug))
  }

  get productsWithLongDelivery(): string[] {
    return this.$store.getters.cart
      .filter((cake: ProductListItem) => cake.shipping_class === 'one-day' || ['gastroboksy'].includes(cake.categories[0].slug))
      .map((cake: ProductListItem) => cake.name)
  }

  get productsWith3daysDelivery(): string[] {
    return this.$store.getters.cart
      .filter((cake: ProductListItem) => cake.shipping_class === 'three-days')
      .map((cake: ProductListItem) => cake.name)
  }

  get availableDeliverySlots() {
    const isLongDelivery = this.productsWithLongDelivery.length || this.productsWith3daysDelivery.length
    const daysToAdd = this.productsWithLongDelivery.length ? 1 : 3
    return isLongDelivery && (this.order.deliveryDate && new Date(this.order.deliveryDate).getDate() === new Date().getDate() + daysToAdd) && new Date().getHours() > 21
      ? Object.fromEntries(Object.entries(DELIVERY_SLOTS).filter(([key]) => key !== 'first'))
      : DELIVERY_SLOTS
  }

  mounted(): void {
    if (!this.deliveryMethods.length) {
      this.$store.dispatch('getDeliveryMethods')
    }

    API.getProductById(CARD_ID).then(({ data }) => {
      this.cardData = {
        id: data.id,
        name: data.name,
        price: data?.price,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        weight: data.acf.cake_min_weight,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        layers: data.acf.cake_min_layers || 0,
        images: [...data.images],
        categories: [...data.categories],
        acf: { ...data.acf },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        filling: {}
      }

      this.orderHasCard = this.currentCart.findIndex((item) => item.id === CARD_ID) > -1
    }).finally(() => {
      this.isCardLoading = false
    })

    const yooKassaScript = document.createElement('script')
    yooKassaScript.setAttribute('src', 'https://yookassa.ru/checkout-widget/v1/checkout-widget.js')
    document.head.appendChild(yooKassaScript)
  }

  destroyed(): void {
    this.$store.dispatch('setPromoCode', null)
  }

  handleDateChange(date: Date): void {
    const isLongDelivery = this.productsWithLongDelivery.length || this.productsWith3daysDelivery.length
    const daysToAdd = this.productsWithLongDelivery.length ? 1 : 3
    this.order.deliveryDate = date
    if (isLongDelivery && this.order.deliverySlot === DELIVERY_SLOTS.first && new Date(this.order.deliveryDate).getDate() === new Date().getDate() + daysToAdd) {
      this.order.deliverySlot = DELIVERY_SLOTS.second
    }
  }

  checkPromoCode(): void {
    if (this.promoCode.length < 3) return

    this.isPromoCodeFetching = true
    this.$store.dispatch('setPromoCode', null)
    this.promoCodeMessage = ''
    OrderAPI.checkPromoCode(this.promoCode).then(response => {
      const promoCode = response && response.data && response.data.length && response.data[0]
      if (promoCode && !promoCode.isExpired) {
        if (this.isCakeHasPrepay && ([LIMITED_COUPON_ID, SCHOOL_COUPON_ID]).includes(promoCode.id)) {
          this.promoCodeMessage = 'Промокод действует только на продукцию из категорий "Фирменные" и "Десерты"'
        } else {
          this.$store.dispatch('setPromoCode', promoCode)
          this.promoCodeMessage = 'Промокод применён'
        }
      } else {
        this.$store.dispatch('setPromoCode', null)
        this.promoCodeMessage = 'Промокод недействителен'
      }
    }).catch(({ response }) => {
      this.promoCodeMessage = response?.data?.message || 'Возникла ошибка, попробуйте ввести другой промокод'
      this.$store.dispatch('setPromoCode', null)
    }).finally(() => {
      this.isPromoCodeFetching = false
    })
  }

  hideSingInMsg(): void {
    window.localStorage.setItem('checkoutSignInMsgHidden', 'true')
    this.isSignInMsgHidden = true
  }

  renderPaymentWidget(token: string, createdOrderID: number, yooOrderID: string): void {
    this.isPaymentActive = true
    // Инициализация виджета. Все параметры обязательные
    const checkout = new (window as any).YooMoneyCheckoutWidget({
      confirmation_token: token, // Токен, который перед проведением оплаты нужно получить от ЮKassa
      return_url: `https://beze.store/order/${createdOrderID}?orderID=${yooOrderID}`,
      error_callback: function(error: unknown) {
        console.log(error)
      }
    })

    // Отображение платежной формы в контейнере
    // После отображения платежной формы метод render возвращает Promise (можно не использовать).
    checkout.render('payment-form').then(() => {
      this.areControlsActive = false
    })
  }

  handleAddCardToOrder() {
    this.orderHasCard ? this.$store.dispatch('removeFromCart', this.cardData?.id) : this.$store.dispatch('addToCart', this.cardData)
    this.orderHasCard = !this.orderHasCard
  }

  validateEmail(email: string) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }

  createOrder(): void {
    if (this.order.email.length && !this.validateEmail(this.order.email)) {
      this.$toasted.error('Укажите корректный email')
      return
    }
    this.isBtnLoading = true
    this.checkItems()

    const activeDeliveryMethod = (this.isDeliveryActive && this.isPickedDeliveryDateExpensive) ? this.expensiveDeliveryCost : this.deliveryMethods.find(method => method.id === this.order.deliveryTypeID)
    const otherCustomerData = `Имя: ${this.otherCustomerInfo.name || 'не указано'}, телефон: ${this.otherCustomerInfo.phone}`
    const newOrder = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      roistat_visit: window?.roistat?.getVisit()?.toString() || '',
      has_agreement: this.isAgreementAccepted,
      customer_id: this.userData?.id,
      applied_coupon: this.$store.getters.promoCode && this.$store.getters.promoCode.code,
      prepayMethod: this.order.prepayMethod,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      prepayValue: PREPAY[PREPAY_METHOD[this.order.prepayMethod]].value,
      customer_note: `
        ${(this.isDeliveryActive ? 'Дата доставки: ' : 'Самовывоз ул. Н. Фёдорова 9, ') + new Date(this.order.deliveryDate!).toLocaleDateString('ru-RU') + '. ' + 'Слот: ' + this.order.deliverySlot + '. '}
        ${this.order.comment.trim()}
        ${this.order.hasAllergy ? '==!Отмечено наличие аллергии!==' : ''}
        ${this.otherCustomerInfo.phone ? '==!В заказе указан другой получатель: ' + otherCustomerData + '!==' : ''}
      `,
      shipping: {
        first_name: this.order.firstName,
        last_name: this.order.lastName,
        address_1: this.isDeliveryActive ? (this.order.deliveryAddress || activeDeliveryMethod?.title) : ''
      },
      billing: {
        phone: this.order.phone,
        email: this.order.email
      },
      line_items: this.currentCart?.map(product => {
        const metaData = []
        if (product.id !== CARD_ID) {
          metaData.push(
            {
              key: 'Количество тортов',
              value: `${product.quantity}`
            },
            {
              key: `${product.acf?.isNumberCake === 'yes' ? 'Кол-во шт' : 'Вес'}`,
              value: `${product.weight}${product.acf?.isNumberCake === 'yes' ? 'шт' : 'кг'}`
            },
            {
              key: 'Начинка',
              value: `${product.filling?.name || 'Не выбрана'}`
            },
            {
              key: 'Предоплата',
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              value: `${PREPAY[PREPAY_METHOD[this.order.prepayMethod]].value * 100}%`
            }
          )
        } else {
          metaData.push(
            {
              key: 'Текст открытки',
              value: this.order.cardText
            }
          )
        }
        return {
          price: Number(product.price) * (product.acf.cake_max_layers ? Number(product.weight) : 1),
          product_id: product.id,
          // тут если у торта есть cake_max_weight то это торт не с фикс. весом и поэтому считать в корзине в админке нужно будет каждый кг
          quantity: product.acf.cake_max_weight ? product.weight : product.quantity,
          meta_data: metaData
        }
      }),
      shipping_lines: [
        {
          method_id: activeDeliveryMethod?.method_id,
          method_title: activeDeliveryMethod?.title,
          total: activeDeliveryMethod?.settings.cost.value
        }
      ]
    }
    OrderAPI.createOrder(newOrder).then(response => {
      window.sessionStorage.setItem('yooOrderID', response?.data?.id)
      this.renderPaymentWidget(
        response?.data?.confirmation.confirmation_token,
        +response?.data?.metadata?.orderID,
        response?.data?.id
      )
    }).catch(error => {
      this.$toasted.error(error.response?.textContent || 'Возникла ошибка при оформлении заказа')
    }).finally(() => {
      this.isBtnLoading = false
    })
  }

  checkItems(): void {
    const curItemsIds = this.currentCart.map(item => item.parent_id || item.id)
    OrderAPI.checkProductsInCart(curItemsIds).then(response => {
      const publishedItems = response?.data?.map(item => item.id) || []
      if (publishedItems.length !== curItemsIds.length) {
        curItemsIds.forEach(id => {
          if (!publishedItems.includes(id)) {
            this.$store.dispatch('removeFromCart', id)
          }
        })
        this.$toasted.info('Некоторые товары в вашей корзине больше не продаются. Мы удалили их из корзины', {
          duration: 5000
        })
      }
    }).catch(error => {
      this.$toasted.error(error.response?.textContent || 'Возникла ошибка')
    })
  }
}
